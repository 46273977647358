import * as React from "react";
// import { AnimatePresence } from 'framer-motion'

import { StoreContextProvider } from "./src/context/storeContext";
import { LoaderProvider } from "./src/utils/LoaderContext";
import { CartProvider } from "./src/utils/CartContext";
import { FiltersProvider } from "./src/utils/FiltersContext";
import { GridObservationProvider } from "./src/utils/GridObservationContext";
import { RosterObservationProvider } from "./src/utils/RosterObservationContext";
import { MenuDropdownProvider } from "./src/utils/MenuDropdownContext";

import { Layout, ShopLayout } from "./src/layouts";

export const wrapRootElement = ({ element }) => {
  return (
    <StoreContextProvider>
      <LoaderProvider>
        <MenuDropdownProvider>
          <RosterObservationProvider>
            <GridObservationProvider>
              <FiltersProvider>
                <CartProvider>{element}</CartProvider>
              </FiltersProvider>
            </GridObservationProvider>
          </RosterObservationProvider>
        </MenuDropdownProvider>
      </LoaderProvider>
    </StoreContextProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  // if(props.pageContext.layout === 'shop') {
  //   return (
  //     <ShopLayout { ...props }>
  //       { element }
  //     </ShopLayout>
  //   )
  // }

  // console.log(props);

  return <Layout {...props}>{element}</Layout>;
};

export const shouldUpdateScroll = false;
