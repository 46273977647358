import * as React from 'react'
import * as S from './styles'
import { globalHistory } from '@reach/router'

import { useGenerateSkewStyle } from '../../../hooks/useGenerateSkewStyle'

export const Link = ({ to, children, skew, ...props }) => {
  const { skewStyle, generateSkewStyle } = useGenerateSkewStyle()
  const path = globalHistory.location.pathname

  React.useEffect(() => {
    const currentPath = path.endsWith('/') ? path.slice(0, -1) : to;

    // console.log(currentPath)

    if(currentPath === to) {
      generateSkewStyle()
    }
  }, [path])

  return (
    <S.Link activeStyle={ skew ? skewStyle : null } to={ to } { ...props }>{ children }</S.Link>
  )
}

export const ExternalLink = ({ children, ...props }) => {
  return (
    <S.ExternalLink { ...props }>{ children }</S.ExternalLink>
  )
}
