import * as React from "react";
import { useLocation } from "@reach/router";
import { useTheme } from "@mui/system";

import { Link } from "../../elements/link";
import { Button } from "../../elements/button";

import {
  useMenuDropdown,
  useMenuDropdownDispatch,
} from "../../../utils/MenuDropdownContext";
import { Box } from "@mui/system";

const variations = {
  linkInternal: LinkInternal,
  linkDropdown: LinkDropdown,
};

export const MenuItem = ({ data }) => {
  const theme = useTheme();
  const { _type, key, increasedDistance } = data;
  const VariationType = variations[_type];

  return (
    VariationType && (
      <Box
        component="li"
        key={key}
        sx={{
          mx: increasedDistance ? 8 : null,
          [theme.breakpoints.up("md")]: {
            // mx: 6,
            mx: increasedDistance ? 12 : null,
          },
          [theme.breakpoints.up("lg")]: {
            // mx: 8,
            mx: increasedDistance ? 16 : null,
          },
        }}
      >
        <VariationType data={data} />
      </Box>
    )
  );
};

function LinkInternal({ data }) {
  const location = useLocation();
  const setMenuDropdown = useMenuDropdownDispatch();
  const menuDropdown = useMenuDropdown();

  const { label, reference } = data;

  if (!reference) return null;

  let slug;
  if (reference._type === "contactSingleton") {
    slug = "/contact";
  } else if (reference._type === "productionSingleton") {
    slug = "/production";
  } else if (reference.slug.current) {
    slug = `/collection/${reference.slug.current}`;
  }

  return (
    <Link
      noUnderline
      location={location}
      header={menuDropdown.state.isOpen ? true : false}
      skew={menuDropdown.state.isOpen ? false : true}
      to={slug}
      onClick={() => {
        setMenuDropdown((prevState) => {
          return { ...prevState, state: { isOpen: false } };
        });
      }}
    >
      {label}
    </Link>
  );
}

function LinkDropdown({ data }) {
  const { label, _key } = data;
  // const [isActive, setActive] = React.useState(false)
  const setMenuDropdown = useMenuDropdownDispatch();
  const menuDropdown = useMenuDropdown();

  function handleMenu() {
    if (_key === menuDropdown.state.key) {
      setMenuDropdown((prevState) => {
        return {
          ...prevState,
          state: {
            isOpen: false,
            key: null,
          },
        };
      });
      return;
    }

    setMenuDropdown((prevState) => {
      return {
        ...prevState,
        state: {
          isOpen: true,
          key: _key,
        },
      };
    });
  }

  return (
    <Button
      skew={menuDropdown.state.key === data._key ? true : false}
      header={menuDropdown.state.isOpen ? true : false}
      noUnderline
      onClick={() => handleMenu()}
    >
      {label}
    </Button>
  );
}
