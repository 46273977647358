import * as React from "react";
import { useLocation } from "@reach/router";
import Box from "@mui/system/Box";

import { Button } from "../elements/button";

import { useElementSize } from "../../hooks/useElementSize";

export const Accordion = ({
  children,
  heading,
  accordionIsOpen,
  setAccordionIsOpen,
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [contentRef, { height }] = useElementSize();
  const location = useLocation();

  React.useEffect(() => {
    // setOpen(false)
    // setAccordionIsOpen(false)
  }, [location]);

  return (
    <div>
      <div>
        <Button
          noUnderline="true"
          skew={isOpen ? true : false}
          onClick={() => {
            setOpen(!isOpen);
            // setAccordionIsOpen(!accordionIsOpen)
          }}
        >
          {heading}
        </Button>
      </div>
      <Box
        sx={{
          maxHeight: isOpen ? `${height}px` : 0,
          overflow: "hidden",
          transition: "0.25s cubic-bezier(0.42, 0, 0, 1)",
        }}
      >
        <Box sx={{ pb: 4 }} ref={contentRef}>
          {children}
        </Box>
      </Box>
    </div>
  );
};
