import * as React from 'react';

export const FiltersContext = React.createContext(null)
export const FiltersDispatchContext = React.createContext(null)

export function FiltersProvider({ children }) {
  const initialFilters = typeof window !== 'undefined' ? getParams() : []
  const [filters, dispatch] = React.useReducer(filtersReducer, initialFilters)

  return (
    <FiltersContext.Provider value={filters}>
      <FiltersDispatchContext.Provider value={dispatch}>
        {children}
      </FiltersDispatchContext.Provider>
    </FiltersContext.Provider>
  )
}

export function useFilters() {
  return React.useContext(FiltersContext);
}

export function useFiltersDispatch() {
  return React.useContext(FiltersDispatchContext);
}

function filtersReducer(filters, action) {
  switch (action.type) {
    case 'added': {
      // key with value already exists, do nothing
      if(filters[action.taxonomy]?.includes(action.value)) {
        return { ...filters }
      }

      return {
        ...filters, [action.taxonomy]: filters[action.taxonomy] ?
        [...filters[action.taxonomy], action.value] : [action.value]
      }
    }
    case 'removed': {
      if(filters[action.taxonomy]) {
        const newValues = filters[action.taxonomy].filter(f => f !== action.value)

        if(newValues.length) {
          filters[action.taxonomy] = newValues
        } else {
          delete filters[action.taxonomy]
        }

        return { ...filters }
      }
    }
    case 'reset': {
      delete filters[action.taxonomy]
      return { ...filters }
    }
    case 'resetAll': {
      return {}
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

function getParams (url = window.location) {
	let params = {};

	new URL(url).searchParams.forEach(function (val, key) {
		if (params[key] !== undefined) {
			if (!Array.isArray(params[key])) {
				params[key] = [params[key]];
			}
			params[key].push(val);
		} else {
			params[key] = [val];
		}
	});

	return params;
}

