import { Link as GatsbyLink } from "gatsby";
// import styled from '@emotion/styled'
import styled from "@mui/system/styled";

export const Header = styled("header")(
  ({ theme, gridIsObserved, headerIsFaded, loader }) => `
  position: sticky;
  /* position: fixed; */
  top: 0;
  right: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  // padding: 25px var(--padding--regular) 0;
  /* transition: opacity 0.25s ease-in-out; */
  /* opacity: 0; */
  transition: opacity 0.5s ease-in-out 1.275s;
  font-size: var(--font-size--large);

  padding: ${theme.spacing(1.5)} ${theme.spacing(2)} 0;



  &:hover {
    opacity: 1;

    > div {
      opacity: 1;
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    /* height: 48px; */
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    /* height: 64px; */
    padding: ${theme.spacing(2)} ${theme.spacing(2)} 0;
    /* opacity: ${gridIsObserved ? 0.3 : 1}; */
    /* opacity: ${headerIsFaded ? 0.3 : 1}; */


    ${
      loader === true
        ? `
      opacity: 0;
      // transition: opacity 0.5s ease-in-out 1s;
    `
        : `
      opacity: 1;
    `
    };

    > div {
      transition: opacity 0.25s ease-in-out;

      ${
        headerIsFaded
          ? `
        opacity: 0.3;
      `
          : `
        opacity: 1;
      `
      };


    }

    /* ${
      headerIsFaded && loader === false
        ? `
      opacity: 0.3;
      // transition: opacity 0.25s ease-in-out;
      transition: opacity 0.5s ease-in-out 1.275s;
    `
        : `
      opacity: 1;
      transition: opacity 0.5s ease-in-out 1.275s;
    `
    }; */

    /* opacity: loader ? 0 : 1,
          transition: 'opacity 0.5s ease-in-out 1.275s', */


  }
`
);

// export const Menu = styled.ul`
//   display: flex;
//   list-style-type: none;
//   padding: 0;
//   margin: 0;

//   li {
//     margin-left: calc(3 * var(--padding--regular));

//     &:first-of-type {
//       margin-left: 0;
//     }
//   }
// `
