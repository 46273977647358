//import styled from '@emotion/styled'
import styled from '@mui/system/styled'
import { FiltersContext } from '../../utils/FiltersContext'

export const MobileMenu = styled('aside')(({ mobileMenuIsOpen, theme }) => `
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: var(--color--primary);
  transition: transform 0.25s cubic-bezier(0.42, 0, 0, 1), visibility 0.25s;
  visibility: ${ mobileMenuIsOpen ? 'visible' : 'hidden'};
  transform:  ${ mobileMenuIsOpen ? 'translateY(0)' : 'translateY(-101%)'};

  @media (min-width: ${theme.breakpoints.values.md}px) {
    display: none;
  }
`)