import * as React from "react";
import * as S from "./styles";
import { useTheme } from "@mui/system";
import Box from "@mui/system/Box";
import Stack from "@mui/system/Stack";
// import { SetFilterProp } from '../filter/types'

// import { useRosterObservation } from '../../utils/RosterObservationContext'
// import { useGridObservation } from '../../utils/GridObservationContext'

import { Button } from "../../elements/button";
import { Link } from "../../elements/link";
import { Wordmark } from "../wordmark";
import { HeaderShop } from "../headerShop";

import { MenuItem } from "./menuItem";

import { useLoader } from "../../../utils/LoaderContext";
import { useSiteSettings } from "../../../hooks/useSiteSettings";
import {
  useMenuDropdown,
  useMenuDropdownDispatch,
} from "../../../utils/MenuDropdownContext";
import { CartIcon } from "../../elements/cartIcon/cartIcon";

// const links = [
//   {
//     text: "Production",
//     url: "/production",
//   },
//   {
//     text: "Contact",
//     url: "/contact",
//   },
//   {
//     text: "Shop",
//     url: "/products-collection/latest",
//   },
// ]

export const Header = ({
  toggleFilterMenu,
  toggleMobileMenu,
  toggleTalentMenu,
  talentMenuIsOpen,
  // toggleRosterMenu,
  // rosterIsOpen,
  location,
  headerIsFaded,
  pageContext,
}) => {
  // const gridIsObserved = useGridObservation()

  const loader = useLoader();

  return (
    <S.Header
      headerIsFaded={headerIsFaded}
      loader={loader}
      // style={{ opacity: pageContext.layout == "shop" ? 0 : 1 }}
    >
      <MobileMenu
        location={location}
        toggleFilterMenu={toggleFilterMenu}
        toggleMobileMenu={toggleMobileMenu}
        pageContext={pageContext}
      />

      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            opacity: pageContext.layout == "shop" ? 1 : 0,
            pointerEvents: pageContext.layout == "shop" ? "auto" : "none",
          }}
        >
          <HeaderShop />
        </Box>

        <Box
          sx={{
            opacity: pageContext.layout == "shop" ? 0 : 1,
            pointerEvents: pageContext.layout == "shop" ? "none" : "auto",
          }}
        >
          <DesktopMenu
            location={location}
            // rosterIsOpen={ rosterIsOpen }
            toggleFilterMenu={toggleFilterMenu}
            // toggleRosterMenu={ toggleRosterMenu }
            toggleTalentMenu={toggleTalentMenu}
            talentMenuIsOpen={talentMenuIsOpen}
          />
        </Box>
      </Box>
    </S.Header>
  );
};

const DesktopMenu = ({
  location,
  // rosterIsOpen,
  // talentMenuIsOpen,
  toggleFilterMenu,
  toggleTalentMenu,
  // toggleRosterMenu
}) => {
  const theme = useTheme();
  const siteSettings = useSiteSettings();

  const { menu } = siteSettings;
  // console.log(menu)

  // console.log(location)

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      >
        <Box
          component="ul"
          sx={{
            display: "flex",
            listStyleType: "none",
            padding: 0,
            margin: 0,
            columnGap: 4,
            [theme.breakpoints.up("md")]: {
              columnGap: 6,
            },
            [theme.breakpoints.up("lg")]: {
              columnGap: 8,
            },
          }}
        >
          {menu.map((menuItem, i) => (
            <MenuItem key={i} data={menuItem} />
          ))}

          {/* <Button
            skew={ talentMenuIsOpen ? true : false }
            header={ talentMenuIsOpen ? true : false }
            noUnderline
            onClick={ () => {
              toggleTalentMenu(!talentMenuIsOpen) 
            }}
          >
            Photographers
          </Button> */}

          {/* { links.map((link, index) => 
            <li key={ index }>
              <Link 
                noUnderline
                skew={ talentMenuIsOpen ? false : true }
                header={ talentMenuIsOpen ? true : false }
                to={`${link.url}`}
                location={ location }
                onClick={ () => toggleTalentMenu(false) }
                { ...link } 
              >
                { link.text }
              </Link>
            </li>) 
          } */}
        </Box>
      </Box>
      <ContextualButton
        location={location}
        // rosterIsOpen={ talentMenuIsOpen }
        toggleFilterMenu={toggleFilterMenu}
        toggleRosterMenu={toggleTalentMenu}
      />
    </Box>
  );
};

const MobileMenu = ({
  location,
  toggleMobileMenu,
  toggleFilterMenu,
  pageContext,
}) => {
  return (
    <Box
      sx={{
        display: { xs: "grid", sm: "none" },
        gridTemplateColumns: "34px 1fr 35px",
        columnGap: 2.5,
        width: "100%",
      }}
    >
      <Button onClick={() => toggleMobileMenu(true)}>
        <svg
          style={{ width: "34px", height: "13px" }}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 34 15"
        >
          <path d="M0 1h34" stroke="#000" strokeWidth="2" />
          <path d="M0 14h34" stroke="#000" strokeWidth="2" />
        </svg>
      </Button>

      <Link to="/" style={{ display: "block", width: "100%" }}>
        <Wordmark />
      </Link>

      {location.pathname == "/" ? (
        <Button onClick={() => toggleFilterMenu(true)}>
          <svg
            style={{ width: "35px", height: "22px" }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 35 22"
          >
            <path
              d="M27.403 6.822c-1.793 0-3.296 1.285-3.659 2.988H0v1.567h23.744c.363 1.703 1.866 2.988 3.659 2.988 1.793 0 3.296-1.285 3.659-2.988H35V9.81h-3.938c-.363-1.703-1.866-2.988-3.659-2.988Zm0 5.976c-1.202 0-2.187-.992-2.187-2.204 0-1.223.985-2.205 2.187-2.205s2.187.993 2.187 2.205a2.196 2.196 0 0 1-2.187 2.204Z"
              fill="#000"
            />
            <path
              d="M14.862 13.623c-1.793 0-3.296 1.286-3.658 2.989H0v1.567h11.204c.352 1.703 1.865 2.988 3.658 2.988s3.307-1.285 3.659-2.988H35v-1.567H18.52c-.362-1.703-1.865-2.988-3.658-2.988Zm0 5.976c-1.202 0-2.187-.992-2.187-2.204 0-1.212.985-2.204 2.187-2.204 1.203 0 2.187.992 2.187 2.204a2.196 2.196 0 0 1-2.187 2.204Z"
              fill="#000"
            />
            <path
              d="M10.675 3.02C10.323 1.305 8.82.02 7.017.02c-1.804 0-3.307 1.285-3.659 2.998H0v1.568h3.358c.363 1.703 1.866 2.977 3.659 2.977 1.793 0 3.295-1.274 3.658-2.977H35V3.019H10.675ZM7.017 5.996c-1.203 0-2.187-.993-2.187-2.204 0-1.212.984-2.205 2.187-2.205 1.202 0 2.186.993 2.186 2.205 0 1.211-.984 2.204-2.186 2.204Z"
              fill="#000"
            />
          </svg>
        </Button>
      ) : null}

      {pageContext.layout == "shop" && <CartIcon />}
    </Box>
  );
};

const ContextualButton = ({
  location,
  rosterIsOpen,
  toggleFilterMenu,
  toggleRosterMenu,
}) => {
  const theme = useTheme();
  const menuDropdown = useMenuDropdown();
  const setMenuDropdown = useMenuDropdownDispatch();

  if (menuDropdown.state.isOpen) {
    return (
      <>
        <Button
          onClick={() => {
            setMenuDropdown((prevState) => {
              return { ...prevState, state: { isOpen: false } };
            });
          }}
          header="true"
          noUnderline="true"
        >
          <Stack
            columnGap={1}
            direction="row"
            sx={{ alignItems: "center", lineHeight: 1 }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", height: "1.15rem" }}
            >
              <svg
                style={{ height: "100%" }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M19.55 1.61 18.33.4l-8.3 8.29L1.73.4.52 1.62l8.29 8.29-8.29 8.3 1.22 1.22 8.29-8.3 8.3 8.3 1.22-1.22-8.3-8.3z" />
              </svg>
            </Box>
            <Box
              sx={{
                display: "none",
                [theme.breakpoints.up("md")]: {
                  display: "block",
                },
              }}
            >
              Close
            </Box>
          </Stack>
        </Button>
      </>
    );
  }

  return (
    <>
      {location.pathname == "/" ? (
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Button
            noUnderline
            onClick={() => toggleFilterMenu(true)}
            header={rosterIsOpen ? true : false}
          >
            <Stack
              columnGap={1.25}
              direction="row"
              sx={{ alignItems: "center", lineHeight: 1 }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", height: "1rem" }}
              >
                <svg
                  style={{ height: "100%" }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 35 22"
                >
                  <path
                    d="M27.403 6.822c-1.793 0-3.296 1.285-3.659 2.988H0v1.567h23.744c.363 1.703 1.866 2.988 3.659 2.988 1.793 0 3.296-1.285 3.659-2.988H35V9.81h-3.938c-.363-1.703-1.866-2.988-3.659-2.988Zm0 5.976c-1.202 0-2.187-.992-2.187-2.204 0-1.223.985-2.205 2.187-2.205s2.187.993 2.187 2.205a2.196 2.196 0 0 1-2.187 2.204Z"
                    fill="#000"
                  />
                  <path
                    d="M14.862 13.623c-1.793 0-3.296 1.286-3.658 2.989H0v1.567h11.204c.352 1.703 1.865 2.988 3.658 2.988s3.307-1.285 3.659-2.988H35v-1.567H18.52c-.362-1.703-1.865-2.988-3.658-2.988Zm0 5.976c-1.202 0-2.187-.992-2.187-2.204 0-1.212.985-2.204 2.187-2.204 1.203 0 2.187.992 2.187 2.204a2.196 2.196 0 0 1-2.187 2.204Z"
                    fill="#000"
                  />
                  <path
                    d="M10.675 3.02C10.323 1.305 8.82.02 7.017.02c-1.804 0-3.307 1.285-3.659 2.998H0v1.568h3.358c.363 1.703 1.866 2.977 3.659 2.977 1.793 0 3.295-1.274 3.658-2.977H35V3.019H10.675ZM7.017 5.996c-1.203 0-2.187-.993-2.187-2.204 0-1.212.984-2.205 2.187-2.205 1.202 0 2.186.993 2.186 2.205 0 1.211-.984 2.204-2.186 2.204Z"
                    fill="#000"
                  />
                </svg>
              </Box>
              <Box
                sx={{
                  display: "none",
                  [theme.breakpoints.up("md")]: {
                    display: "block",
                  },
                }}
              >
                Filter
              </Box>
            </Stack>
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Link
            to="/"
            header={rosterIsOpen ? true : false}
            noUnderline
            onClick={() => {
              setMenuDropdown((prevState) => {
                return {
                  ...prevState,
                  state: {
                    isOpen: false,
                    key: null,
                  },
                };
              });
            }}
          >
            Home
          </Link>
        </Box>
      )}
    </>
  );
};
