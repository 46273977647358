import * as React from 'react'
import * as S from './styles'

import { useFiltersDispatch } from '../../utils/FiltersContext'

// import { Taxonomy, Item } from './types'

const handleChange = (event, dispatch, reset) => {
  const { dataset, checked } = event.target;

  if(checked) {
    if(reset) {
      dispatch({
        type: 'reset',
        //id: `${dataset.taxonomy}-${dataset.value}`,
        taxonomy: dataset.taxonomy,
        //value: dataset.value,
      })
    } else {
      dispatch({
        type: 'added',
        id: `${dataset.taxonomy}-${dataset.value}`,
        taxonomy: dataset.taxonomy,
        value: dataset.value,
      })
    }
  } else {
    dispatch({
      type: 'removed',
      id: `${dataset.taxonomy}-${dataset.value}`,
      taxonomy: dataset.taxonomy,
      value: dataset.value,
    })
  }
}

/*
type Props = {
  taxonomy: Taxonomy,
  item: Item,
}
*/

export const FilterToggle = ({
  taxonomy, 
  entry,
  reset,
  checked
}) => {
  const dispatch = useFiltersDispatch();

  // if(!entry) return

  if(reset) {
    return (
      <S.Pill>
        <input
          checked={checked}
          type="checkbox" 
          id={`${taxonomy}-reset`} 
          name={taxonomy} 
          value='reset'
          data-taxonomy={taxonomy}
          data-value='reset'
          onChange={() => handleChange(event, dispatch, reset)}
        />
        <label htmlFor={`${taxonomy}-reset`}>
          All
        </label>
      </S.Pill>
    )
  }  

  return (
    <S.Pill>
      <input 
        checked={checked}
        type="checkbox" 
        id={`${taxonomy}-${entry?.slug?.current}`} 
        name={taxonomy} 
        value={entry?.title}
        data-taxonomy={taxonomy}
        data-value={entry?.slug?.current}
        onChange={() => handleChange(event, dispatch)}
      />
      <label htmlFor={`${taxonomy}-${entry?.slug?.current}`}>
        {entry?.title}
      </label>
    </S.Pill>
  )
}