import * as React from 'react';

export const CartContext = React.createContext(null)
export const CartDispatchContext = React.createContext(null)

export function CartProvider({ children }) {
  // const initialFilters = getParams()
  // const [filters, dispatch] = React.useReducer(filtersReducer, initialFilters)

  const initialState = {
    isOpen: false,
    checkout: {
      lineItems: [],
    },
  }

  const [state, dispatch] = React.useReducer(cartReducer, initialState)

  // console.log(state)

  return (
    <CartContext.Provider value={ state }>
      <CartDispatchContext.Provider value={ dispatch }>
        { children }
      </CartDispatchContext.Provider>
    </CartContext.Provider>
  )
}

export function useCart() {
  return React.useContext(CartContext);
}

export function useCartDispatch() {
  return React.useContext(CartDispatchContext);
}

function cartReducer(state, action) {
  switch (action.type) {
    case 'OPEN_CART': {
      // console.log('opening cart')

      return {
        ...state,
        isOpen: true,
      }
      // return {
      //   ...filters, [action.taxonomy]: filters[action.taxonomy] ?
      //   [...filters[action.taxonomy], action.value] : [action.value]
      // }
    }

    case 'CLOSE_CART': {
      return {
        ...state,
        isOpen: false,
      }
    }

    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

// function getParams (url = window.location) {
// 	let params = {};

// 	new URL(url).searchParams.forEach(function (val, key) {
// 		if (params[key] !== undefined) {
// 			if (!Array.isArray(params[key])) {
// 				params[key] = [params[key]];
// 			}
// 			params[key].push(val);
// 		} else {
// 			params[key] = [val];
// 		}
// 	});

// 	return params;
// }

