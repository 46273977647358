import { Link as GatsbyLink } from "gatsby";
import styled from "@mui/system/styled";

export const ExternalLink = styled("a", {
  shouldForwardProp: (prop) => prop !== "small" && prop !== "pill",
})(
  ({ theme, pill, small }) => `
  color: var(--color--secondary);
  user-select: none;

  ${
    pill
      ? `
    text-decoration: none;
    padding: 0.245em 0.6125em;
    border: 1px solid currentColor;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  `
      : ""
  }

  &:hover {
    cursor: pointer;
    color: var(--color--primary);
    path {
      fill: var(--color--primary);
    }

    ${
      pill
        ? `
      cursor: pointer;
      color: var(--color--tertiary);
      background-color: var(--color--secondary);
    `
        : ""
    }
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    font-size: ${
      small ? "var(--font-size--default)" : "var(--font-size--large)"
    }; 

    ${
      pill
        ? `
      small ? "var(--font-size--default)" : "var(--font-size--large)"
    `
        : ""
    }
  }
`
);

export const Link = styled(GatsbyLink, {
  shouldForwardProp: (prop) =>
    prop !== "icon" &&
    prop !== "header" &&
    prop !== "noUnderline" &&
    prop !== "secondary" &&
    prop !== "small",
})(
  ({ theme, noUnderline, icon, header, secondary, small }) => `
  color: var(--color--secondary);
  text-decoration: ${noUnderline ? "none" : "underline"};
  display: block;
  transform-origin: left;
  transition: transform 0.05s ease-out;
  opacity: ${secondary ? 0.2 : 1};
  transition: transform 0.075s ease-out;
  user-select: none;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: var(--color--primary);

      ${
        icon
          ? `
        path {
          fill: var(--color--primary);
        }
      `
          : ""
      }

      ${
        header
          ? `
        opacity: 0.2;
        color: var(--color--secondary);
      `
          : ""
      }

      ${
        secondary
          ? `
        color: var(--color--secondary);
        opacity: 1;
      `
          : ""
      }
    }
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    font-size: ${
      small ? "var(--font-size--default)" : "var(--font-size--large)"
    }; 
  }
`
);
