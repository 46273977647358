// import styled from '@emotion/styled'
import styled from '@mui/system/styled'

export const Heading = styled('h1')(({ narrow, large, theme }) => `
  margin: 0;
  font-weight: normal;
  /* font-size: var(--font-size--large); */

  /* font-size: ${({large}) =>
    large  && 'var(--font-size--xlarge)' ||
    'var(--font-size--large)'
  }; */

  ${large ? `
    font-size: var(--font-size--xlarge);
  `: `
    font-size: var(--font-size--large);
  `}

  line-height: 1;

  /* font-family: ${({narrow}) =>
    narrow  && 'var(--font--narrow)' ||
    'var(--font--default)'
  }; */

  ${narrow ? `
    font-family: var(--font--narrow);
  `: ``}

  @media (min-width: ${theme.breakpoints.values.md}px) {
    letter-spacing: -0.1rem;
  }
`)