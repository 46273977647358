import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTheme } from '@mui/system'
import Box from '@mui/system/Box'
import { Stack } from '@mui/system'
import { globalHistory } from '@reach/router'
import * as S from './styles'
// import { FilterMenuProps } from './types'
import { FilterToggle } from './filterToggle'
import { CopyFilter } from './copyFilter'

import { Button } from '../elements/button'

import { useSiteSettings } from '../../hooks/useSiteSettings'
import { useUrlUpdate } from '../../hooks/useUrlUpdate'
// import { useRoster } from '../../hooks/useRoster'
import { useFilters, useFiltersDispatch } from '../../utils/FiltersContext'

export const Filter = ({
  filterMenuIsOpen, 
  toggleFilterMenu,
  // location
}) => {
  const theme = useTheme()
  const location = globalHistory.location
  const dispatch = useFiltersDispatch()
  const query = useFilters()
  // const roster = useRoster()

  // const talents = roster.map(rosterEntry => rosterEntry?.talent)

  // console.log(query)

  const {filter} = useSiteSettings()
  // console.log(siteSettings)

  // const urlUpdate = useUrlUpdate()


  // allSanityTalent(
  //   filter: {talentCategory: {elemMatch: {slug: {current: {eq: "photographers"}}}}}
  // ) {
  //   edges {
  //     node {
  //       id
  //       title
  //     }
  //   }
  // }

  const {
    // allSanityTalent: {nodes: talents},
    allSanityCategory: {nodes: categories}
  } = useStaticQuery(graphql`
    query FilterQuery {
      # allSanityTalent(sort: {title: ASC}) {
      #   nodes {
      #     slug {
      #       current
      #     }
      #     title
      #   }
      # }
      allSanityCategory(sort: {title: ASC}) {
        nodes {
          slug {
            current
          }
          title
        }
      }
    }
  `)

  // console.log(talents)

  React.useEffect(() => {
    if(!Object.keys(query).length || location.state?.modal) return
    const searchParams = createSearchParams(query).toString()

    // quick hack, needs to whitelist allowed taxonomies instead
    if(query['fbclid']) {
      delete query['fbclid']
    }

    useUrlUpdate(`?${ searchParams }`)
  }, [query])

  // Clear Filter Query when leaving index/modal opened on index
  React.useEffect(() => {
    if(location.state?.modal || location.pathname === '/') return
    
    dispatch({ type: 'resetAll' })
  },[location.pathname])

  // const modifiedFilter = filter.map((f) => {
  //   const entries = f.entries.map((n) => ({ title: n.talent.title, slug: n.talent.slug }))
  //   // console.log(entries)

  //   return { 
  //     ...f,
  //     slug: 'talent',
  //     entries: entries
  //   }
  // })

  const modifiedFilter = flattenFilter(filter)

  const taxonomies = [
    ...modifiedFilter,
    {
      slug: 'categories',
      title: 'Categories',
      entries: categories,
    }
  ]

  return (
    <>
      <S.Menu filterMenuIsOpen={filterMenuIsOpen}>
        <S.Container>
          <S.UI>
            <Button 
              header
              noUnderline
              onClick={() => toggleFilterMenu(false)}
            >
              <Stack 
                columnGap={ 1.25 }
                direction='row' 
                sx={{ alignItems: 'center', lineHeight: 1 }}
              >
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    height: '2rem',
                    [theme.breakpoints.up('md')]: {
                      height: '1.15rem',
                    }
                  }}>
                    <svg style={{ height: '100%' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M19.55 1.61 18.33.4l-8.3 8.29L1.73.4.52 1.62l8.29 8.29-8.29 8.3 1.22 1.22 8.29-8.3 8.3 8.3 1.22-1.22-8.3-8.3z"/>
                    </svg>
                  </Box>
                <Box 
                  sx={{
                    display: 'none',
                    [theme.breakpoints.up('md')]: {
                      display: 'block',
                    }
                  }}
                >
                  Close
                </Box>
              </Stack>
            </Button>
          </S.UI>

          {/* <form> */}
            { taxonomies.map((taxonomy, index) =>
              <S.Section key={ index }>
                <S.Title>{taxonomy.title}</S.Title>
                <S.List>
                  <li>
                    <FilterToggle 
                      reset
                      taxonomy={taxonomy.slug}
                      checked={!query[`${taxonomy.slug}`]?.length > 0}
                    />
                  </li>
                  {taxonomy.entries.map((entry, index) => {
                    const checked = query[`${ taxonomy.slug }`]?.includes(entry.slug?.current) ? true : false

                    // console.log(entry)

                    return (
                      <li key={index}>
                        <FilterToggle 
                          taxonomy={taxonomy.slug} 
                          entry={entry} 
                          checked={checked}
                        />
                      </li>
                    )
                  })}
                </S.List>
              </S.Section>
            )}
          {/* </form> */}

          <S.Section style={{ 
            marginTop: '2rem',
            display: 'flex',
            maxWidth: '100%',
          }}>
            <CopyFilter />
            <Box
              sx={{ marginLeft: 'auto' }}
            >
            
              <Button 
                header
                noUnderline
                onClick={() => toggleFilterMenu(false)}
              >
                <Stack 
                  columnGap={ 1.25 }
                  direction='row' 
                  sx={{ alignItems: 'center', lineHeight: 1 }}
                >
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      height: '2rem',
                      [theme.breakpoints.up('md')]: {
                        height: '1.15rem',
                      }
                    }}>
                      <svg style={{ height: '100%' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.8 20.2"><path d="M16.9 19.8c-6.7 0-12.8-3.5-16.1-9.3l-.2-.4.2-.4C4.1 4 10.3.4 16.9.4S29.7 4 33 9.7l.2.4-.2.4c-3.3 5.8-9.4 9.3-16.1 9.3zM2.3 10.1c3.2 5.1 8.6 8.1 14.6 8.1s11.5-3 14.6-8.1c-3.1-5-8.7-8.2-14.6-8.2S5.4 5.1 2.3 10.1z"/><path d="M16.9 17.1c-3.9 0-7-3.2-7-7 0-3.9 3.2-7 7-7s7.1 3.2 7.1 7c0 3.9-3.2 7-7.1 7zm0-12.6c-3.1 0-5.5 2.5-5.5 5.5 0 3.1 2.5 5.5 5.5 5.5s5.6-2.5 5.6-5.5-2.5-5.5-5.6-5.5z"/></svg>
                    </Box>
                  <Box 
                    sx={{
                      display: 'none',
                      [theme.breakpoints.up('md')]: {
                        display: 'block',
                      }
                    }}
                  >
                    View selection
                  </Box>
                </Stack>
              </Button>

            </Box>
          </S.Section>
        </S.Container>
      </S.Menu>
      { filterMenuIsOpen ?
        <Box
          onClick={() => toggleFilterMenu(false)}
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 5,
          }}
        />: null
      }
    </>
  )
}

function createSearchParams(params) {
  const searchParams = new URLSearchParams()
  Object.entries(params).forEach(([key, values]) => {
    if (Array.isArray(values)) {
      values.forEach((value) => {
        searchParams.append(key, value);
      });
    } else {
      searchParams.append(key, values);
    }
  });

  return searchParams;
}

function flattenFilter(object) {
  return object.map((f) => {
    const entries = f.entries.map((n) => ({ title: n.talent.title, slug: n.talent.slug }))
    // console.log(entries)

    return { 
      ...f,
      slug: 'talent',
      entries: entries
    }
  })
}