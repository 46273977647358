import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as S from "./styles";

import { useMenuDropdown } from "../../utils/MenuDropdownContext";
import { useMousePosition } from "../../hooks/useMousePosition";

export const Preview = ({ isHovered, preview }) => {
  const mousePosition = useMousePosition();
  const menuDropdown = useMenuDropdown();

  // console.log(preview);

  // const activeMenuKey = menuDropdown.state.key;
  // const activeMenu = menuDropdown.menus.filter(
  //   (el) => el._key === activeMenuKey
  // );
  // const entries = activeMenu[0]?.talentCategories
  //   ? activeMenu[0].talentCategories
  //   : null;

  const transform = `translate(${mousePosition.x}px, ${mousePosition.y}px)`;

  // console.log(entries);

  if (!preview) return null;

  const imageData = getImage(preview?.asset);

  return (
    <S.Preview isHovered={isHovered} style={{ transform }}>
      <S.PreviewInner>
        {preview && (
          <GatsbyImage
            // key={index}
            image={imageData}
            alt=""
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              // opacity: index == preview ? "1" : "0",
            }}
          />
        )}

        {/* {entries &&
          entries.map((entry, index) => {
            const imageData = getImage(entry.preview?.asset);
            if (!imageData) return null;

            return (
              <GatsbyImage
                key={index}
                image={imageData}
                alt=""
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  bottom: "0",
                  left: "0",
                  opacity: index == preview ? "1" : "0",
                }}
              />
            );
          })} */}
      </S.PreviewInner>
    </S.Preview>
  );
};
