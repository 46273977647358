import * as React from 'react'
import { createTheme, ThemeProvider } from '@mui/system'

const theme = createTheme({
  breakpoints: {
    values: {
      'xs': 0,
      'sm': 640,
      'md': 768,
      'lg': 1024,
      'xl': 1280,
      '2xl': 1536,
    }
  }
})

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={ theme }>
      { element }
    </ThemeProvider>
  )
}