import * as React from 'react'

export const useGenerateSkewStyle = () => {
  const [skewStyle, setSkewStyle] = React.useState(null)

  const generateSkewStyle = () => {  
    const skewValues = [12.5, 26, 35]
    const randomSkewValue = randChoice(skewValues)
    const skewX = randomSkewValue * (Math.round(Math.random()) ? 1 : -1)
  
    const scaleValues = [0.85, 1, 1.05]
    const randomScaleValue = randChoice(scaleValues)
    const scaleX = randomScaleValue
  
    setSkewStyle({
      transformOrigin: `0% 50%`,
      transform: `skew(${skewX}deg) scaleX(${scaleX})`
    })
  }

  return { skewStyle, generateSkewStyle};  
}

const random = (min, max) => {
  const randomNumber = (Math.floor(Math.random() * (max - min)) + min) * (Math.round(Math.random()) ? 1 : -1)
  return randomNumber
};

function randChoice(arr) {
  return arr[Math.floor(Math.random() * arr.length)]
}