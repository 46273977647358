import * as React from "react";

import { Box, Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { ProductCounter } from "./productCounter";

import {
  useUpdateItemsFromCart,
  useRemoveItemFromCart,
  client,
} from "../../context/storeContext";

export const LineItem = ({
  id,
  title,
  quantity,
  // variant: { price, compareAtPrice, image },
  variant,
  customAttributes,
}) => {
  const removeFromCart = useRemoveItemFromCart();
  const updateItemsFromCart = useUpdateItemsFromCart();

  const changeQuantity = (quantity) => {
    updateItemsFromCart({ id, quantity: quantity });
  };

  const itemImage = client.image.helpers.imageForSize(variant.image, {
    maxWidth: 300,
    maxHeight: 300,
  });

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid xs={3} md={3.5}>
        <Box
          sx={{
            aspectRatio: 1,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              lineHeight: 0,
            }}
            src={itemImage}
            alt={`${title} – ${variant.title}`}
          />
        </Box>
      </Grid>
      <Grid xs={9} md={8.5}>
        <Stack sx={{ height: "100%" }}>
          <span>{title}</span>
          {variant.title && variant.title != "Default Title" && (
            <span>{variant.title} </span>
          )}
          <span style={{ fontFamily: "var(--font--narrow)" }}>
            €{parseFloat(variant.price.amount)}
          </span>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              mt: "auto",
              pt: { xs: 1.5, md: 2 },
              gap: 2,
              width: "100%",
            }}
          >
            <ProductCounter
              // key={item.id}
              // id={item.id}
              defaultCount={quantity}
              onUpdate={changeQuantity}
            />
            <Box
              component="button"
              sx={{
                cursor: "pointer",
                appearance: "none",
                border: "none",
                background: "none",
                fontFamily: "var(--font--narrow)",
                color: "var(--color--secondary)",
                ml: { xs: "auto", md: "initial" },
              }}
              onClick={() => removeFromCart(id)}
            >
              Remove
            </Box>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};
