// import { Link as GatsbyLink } from 'gatsby'
// import styled from '@emotion/styled'
import styled from "@mui/system/styled";

export const Menu = styled("aside")(
  ({ theme, filterMenuIsOpen }) => `
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  max-height: 100dvh;
  overflow: scroll;
  z-index: 75;
  background-color: var(--color--primary);
  // min-height: 80vh;
  transition: transform 0.25s cubic-bezier(0.42, 0, 0, 1), visibility 0.25s;
  visibility: ${filterMenuIsOpen ? "visible" : "hidden"};
  transform:  ${filterMenuIsOpen ? "translateY(0)" : "translateY(-101%)"};
`
);

export const Container = styled("div")(
  ({ theme }) => `
  position: relative;
  overflow: hidden;
  // margin: 25px var(--padding--regular);
  margin: ${theme.spacing(2.5)} ${theme.spacing(2)} ${theme.spacing(2)};

  @media (min-width: ${theme.breakpoints.values.md}px) {
    margin: ${theme.spacing(3)} ${theme.spacing(2)};
  }
`
);

export const UI = styled("div")`
  position: absolute;
  right: 0;
  top: 0;
`;

export const Button = styled("button")(
  ({ theme }) => `
  appearance: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    font-size: var(--font-size--large);
  }
`
);

export const Section = styled("section")(
  ({ theme }) => `
  // margin-top: calc(2 * var(--padding--regular));
  margin-top: ${theme.spacing(4)};

  &:first-of-type {
    // margin-top: 0;
    margin-top: ${theme.spacing(0.75)};
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 90%;
    margin-top: ${theme.spacing(5)};

    &:first-of-type {
      margin-top: 0;
    }
  }
`
);

export const Title = styled("div")(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(2)};
  font-size: 13px;
  letterSpacing: -0.02em;
  text-transform: uppercase;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    // margin-bottom: ${theme.spacing(3)};
  }
`
);

export const List = styled("ul")(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: var(--font-size--large);
  /* gap: ${theme.spacing(3)} ${theme.spacing(1)}; */
  gap: 0.95em 0.5em;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    gap: 0.9em 0.5em;
  }
`
);

export const Pill = styled("div")(
  ({ theme }) => `
  input {
    display: none;

    + label {
        user-select: none;
        // font-size: var(--font-size--large);
        // padding: calc(var(--padding--regular)/2) calc(var(--padding--regular) * 1.25);
        /* padding: ${theme.spacing(0.75)} ${theme.spacing(1.5)}; */
        padding: 0.245em 0.6125em;
        border: 1px solid var(--color--secondary);
        border-radius: 40px;
        cursor: pointer;
        transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
      }

    &:checked + label {
      background-color: var(--color--secondary);
      color: var(--color--primary);
    }
  }

  @media (hover: hover) {
    label:hover {
      background-color: var(--color--secondary);
      color: var(--color--primary); 
    }
  }
  
  &.active {
    label {
      background-color: var(--color--secondary);
      color: var(--color--primary);
    }
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    input {
      + label {
        font-size: var(--font-size--large);
        /* padding: ${theme.spacing(1)} ${theme.spacing(2.5)}; */
        border-width: 2px;
      }
    }
  }
`
);

// export const Pill = styled('div')`
//   input {
//     display: none;

//     + label {
//       user-select: none;
//       font-size: var(--font-size--large);
//       padding: calc(var(--padding--regular)/2) calc(var(--padding--regular) * 1.25);
//       border: 1px solid currentColor;
//       cursor: pointer;
//       transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
//     }

//     &:checked + label {
//       background-color: var(--color--secondary);
//       color: var(--color--primary);
//     }
//   }

//   label {
//     border-radius: 40px;
//   }

//   label:hover {
//     background-color: var(--color--secondary);
//     color: var(--color--primary);
//   }

//   &.active {
//     label {
//       background-color: var(--color--secondary);
//       color: var(--color--primary);
//     }
//   }
// `

export const Notification = styled("span")`
  transition: ${({ active }) =>
    active ? "opacity 0s ease-in-out" : "opacity 0.5s ease-in-out"};
  opacity: ${({ active }) => (active ? "1" : "0")};
`;
