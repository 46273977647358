import * as React from 'react'
import { useTheme } from '@mui/system'
import Box from '@mui/system/Box'
import { Stack } from '@mui/system'
import { globalHistory } from '@reach/router'

import { Link } from '../../elements/link'


export const CloseModal = ({ closeModal }) => {
  const theme = useTheme()

  const location = globalHistory.location
  const { state } = location

  return (
    <Box 
      sx={{ 
        position: 'fixed', 
        top: '0', 
        right: '0', 
        zIndex: '10',
        p: 2,
        pt: { xs: 2.5, md: 2 },
        fontSize: 'var(--font-size--large)',
        // mixBlendMode: 'difference',
      }}
    >
      <Link
        to={ state.previousUrlPath }
        noUnderline
        icon
        aria-label="close modal"
        onClick={(e) => {
          e.preventDefault()
          closeModal()
        }}
      >
        <Stack 
          columnGap={ 1.25 }
          direction='row' 
          sx={{ alignItems: 'center', lineHeight: 1 }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            height: '2rem',
            [theme.breakpoints.up('md')]: {
              height: '1.15rem',
            }
          }}>
            <svg style={{ height: '100%' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path style={{ /* fill: '#ffffff' */ }} d="M19.55 1.61 18.33.4l-8.3 8.29L1.73.4.52 1.62l8.29 8.29-8.29 8.3 1.22 1.22 8.29-8.3 8.3 8.3 1.22-1.22-8.3-8.3z"/>
            </svg>
          </Box>
          <Box 
            sx={{
              display: 'none',
              [theme.breakpoints.up('md')]: {
                display: 'block',
              }
            }}
          >
            Close
          </Box>
        </Stack>

      </Link>
    </Box>
  )
}