import * as React from "react";
import * as S from "./styles";
import Box from "@mui/system/Box";
import Stack from "@mui/system/Stack";

import { useRoster } from "../../../hooks/useRoster";
import { useSiteSettings } from "../../../hooks/useSiteSettings";

// import { Accordion } from '../../accordion'
// import { Link } from '../../elements/link'
import { MenuItem } from "./menuItem";
import { Button } from "../../elements/button";
import { Wordmark } from "../wordmark";

// const links = [
//   {
//     text: "Production",
//     url: "/production",
//   },
//   {
//     text: "Contact",
//     url: "/contact",
//   },
// ]

export const MobileMenu = ({
  mobileMenuIsOpen,
  toggleMobileMenu,
  location,
}) => {
  // const roster = useRoster()
  const siteSettings = useSiteSettings();
  const { menu } = siteSettings;

  const [accordionIsOpen, setAccordionIsOpen] = React.useState(false);

  return (
    <S.MobileMenu mobileMenuIsOpen={mobileMenuIsOpen}>
      <Stack
        sx={{
          p: 2,
          height: "100dvh",
          boxSizing: "border-box",
          overflow: "scroll",
        }}
      >
        <Box sx={{ ml: "auto", pt: 0.5 }}>
          <Button onClick={() => toggleMobileMenu(false)}>
            <svg
              style={{ width: "26px", height: "26px" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M19.55 1.61 18.33.4l-8.3 8.29L1.73.4.52 1.62l8.29 8.29-8.29 8.3 1.22 1.22 8.29-8.3 8.3 8.3 1.22-1.22-8.3-8.3z" />
            </svg>
          </Button>
        </Box>

        <Box
          component="ul"
          sx={{
            listStyleType: "none",
            p: 0,
            m: 0,
            mt: 2,
            fontSize: "var(--font-size--mobileMenu)",
          }}
        >
          {menu.map((menuItem, i) => (
            <MenuItem
              key={i}
              data={menuItem}
              toggleMobileMenu={toggleMobileMenu}
              setAccordionIsOpen={setAccordionIsOpen}
              accordionIsOpen={accordionIsOpen}
            />
          ))}

          {/* <li>
          <Accordion 
            heading='Photographers'
          >
            <Box
              component='ul'
              sx={{
                listStyleType: 'none',
                p: 0,
                m: 0,
                mb: 2,
                fontSize: 'var(--font-size--mobileMenu)',
              }}
            >
              { roster.map((entry, index) => 
                <Box 
                  key={index}
                  component='li'
                >
                  <Link 
                    noUnderline
                    to={`/${entry.talent?.slug?.current}`}
                    style={{ width: 'fit-content' }}
                    onClick={ (event) => { 
                      toggleMobileMenu(false)
                    }}
                  >
                    {entry.talent?.title}
                  </Link>
                </Box> 
              )}
            </Box>
          </Accordion>
          </li> */}

          {/* { links.map((link, index) => 
            <li key={ index }>
              <Link 
                noUnderline
                skew
                to={ link.url }
                location={ location }
                onClick={ (event) => { 
                  toggleMobileMenu(false)
                }}
                { ...link } 
              >
                { link.text }
              </Link>
            </li>) 
          } */}
        </Box>

        <Box sx={{ mt: "auto", pt: 3 }}>
          <Box
            sx={{
              height: 45,
            }}
          >
            <Wordmark />
          </Box>
          <Box
            component="p"
            sx={{
              letterSpacing: "-0.02em",
              textAlign: "center",
              fontSize: 14,
              my: 0.5,
            }}
          >
            {/* {siteSettings.summary} */}
            Bad Land is a creative management and production company for still
            image and moving content based in Stockholm and Copenhagen.
          </Box>
        </Box>
      </Stack>
    </S.MobileMenu>
  );
};
