import * as React from 'react'
import { Link, navigate } from 'gatsby'
import ReactModal from 'react-modal'
import { globalHistory } from '@reach/router'

import { CloseModal } from './closeModal'

ReactModal.setAppElement('#___gatsby')

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.58)",
    zIndex: 2000,
  },
  content: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",

    // maxWidth: "960px",
    // margin: "32px auto",

    padding: 0,
    border: 0,
    borderRadius: 0,
    backgroundColor: '#fff',
    overflow: 'scroll',
    height: '100dvh',
  },
};



export const Modal = ({ children }) => {
  const [modalOpen, setModalOpen] = React.useState(true)

  const closeModal = () => {
    setModalOpen(false)
  
    const location = globalHistory.location
    const { state } = location

    // console.log(state)

    navigate(-1)
    
    // navigate(state.previousUrlPath, {
    //   state: { disableScrollUpdate: true }
    // })
  }

  return (
    <ReactModal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Modal"
    >

      <CloseModal 
        closeModal={ closeModal } 
      />

      { children }
    </ReactModal>
  )

  // const [modalOpen, setModalOpen] = React.useState(true);

  // const closeModal = () => {
  //   setModalOpen(false);
  
  //   const location = globalHistory.location
  //   const { state } = location
  
  //   navigate(state.previousUrlPath, {
  //     state: {
  //       disableScrollUpdate: true
  //     }
  //   })
  // }

  // return (
  //   <Modal
  //     isOpen={modalOpen}
  //     onRequestClose={closeModal}
  //     style={modalStyles}
  //     contentLabel="Modal"
  //   >
  //     <Link
  //       to="/"
  //       aria-label="close modal"
  //       onClick={(e) => {
  //         e.preventDefault();
  //         closeModal();
  //       }}
  //     >
  //       Close
  //     </Link>

  //     Modal
  //   </Modal>
  // )
}
