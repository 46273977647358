import * as React from "react";
import * as S from "./styles";
import { Box, Stack } from "@mui/system";

import { Link } from "../../elements/link";
// import { Link } from 'gatsby'

import { useTheme } from "@mui/system";
// import { useCartDispatch } from '../../../utils/CartContext'
import { useCollections } from "../../../hooks/useCollection";

import { useCartCount, useToggleCart } from "../../../context/storeContext";
import { CartIcon } from "../../elements/cartIcon/cartIcon";
import { useSiteSettings } from "../../../hooks/useSiteSettings";

// const collections = [
//   {
//     label: 'Latest',
//     path: '/shop'
//   },
//   {
//     label: 'Merchandise',
//   },
//   {
//     label: 'Prints',
//   },
//   {
//     label: 'Publications',
//   }
// ]

export const HeaderShop = ({ headerIsFaded }) => {
  const theme = useTheme();
  // const cartDispatch = useCartDispatch()
  const collections = useCollections();
  const toggleCart = useToggleCart();
  const cartCount = useCartCount();

  const siteSettings = useSiteSettings();
  const { shopMenu } = siteSettings;
  // console.log(shopMenu);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Stack direction="row" sx={{ gap: 2 }}>
        <Link to="/" noUnderline icon>
          <Stack
            columnGap={1.25}
            direction="row"
            sx={{ alignItems: "center", lineHeight: 1 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "2rem",
                [theme.breakpoints.up("md")]: {
                  height: "1.15rem",
                },
              }}
            >
              <svg
                style={{ height: "100%" }}
                viewBox="0 0 18 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.4842 0.499023L17.6814 2.69863L4.41516 16L17.6814 29.2806L15.4842 31.501L0 16L15.4842 0.499023Z"
                  fill="black"
                />
              </svg>
            </Box>
            <Box
              sx={{
                display: "none",
                [theme.breakpoints.up("md")]: {
                  display: "block",
                },
              }}
            >
              Bad Land
            </Box>
          </Stack>
        </Link>
      </Stack>
      <div>
        <Stack
          component="ul"
          direction="row"
          sx={{
            listStyleType: "none",
            columnGap: 7,
            m: 0,
            p: 0,
          }}
        >
          {shopMenu.map((collection, index) => {
            // console.log(collection.reference.slug.current);

            if (!collection?.reference?.slug?.current) return;

            return (
              <li key={index}>
                <Link
                  noUnderline
                  skew={true}
                  // header={ rosterIsOpen ? true : false }
                  to={`/collection/${collection.reference.slug.current}`}
                >
                  {collection.label}
                </Link>
              </li>
            );
          })}
        </Stack>
      </div>
      <div>
        <CartIcon />
      </div>
    </Box>
  );
};
