import * as React from "react";
import { Box, Stack } from "@mui/system";

export const ProductCounter = ({ onUpdate, defaultCount }) => {
  const [lineQuantity, setLineQuantity] = React.useState(defaultCount);

  React.useEffect(() => {
    setLineQuantity(defaultCount);
  }, [defaultCount]);

  const updateQuantity = React.useCallback((count) => {
    // const count = max ? clampRange(amount, 1, max) : amount

    if (count < 1) return;
    setLineQuantity(count);

    if (onUpdate) {
      onUpdate(count);
    }
  }, []);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        border: "1px solid",
        borderRadius: "50px",
        gap: 3,
        py: { xs: 0.5, md: 0.375 },
        px: 2,
      }}
    >
      <Box
        sx={{ cursor: "pointer", userSelect: "none" }}
        onClick={() => updateQuantity(lineQuantity - 1)}
      >
        -
      </Box>
      <Box>{lineQuantity}</Box>
      <Box
        sx={{ cursor: "pointer", userSelect: "none" }}
        onClick={() => updateQuantity(lineQuantity + 1)}
      >
        +
      </Box>
    </Stack>
  );
};
