// import styled from '@emotion/styled'
import styled from "@mui/system/styled";

export const Button = styled("button")(
  ({ theme, noUnderline, header, secondary, skew }) => `
  /* Reset default button styling */
  appearance: none;
  background: transparent;
  border: 0;
  padding: 0;
  text-decoration: ${noUnderline ? "none" : "underline"};
  color: var(--color--secondary);
  opacity: ${secondary ? 0.2 : 1};
  transition: transform 0.075s ease-out;
  user-select: none;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: var(--color--primary);

      path {
        fill: var(--color--primary);
      }

      ${
        header
          ? `
        opacity: 0.2;
        color: var(--color--secondary);

        path {
          // opacity: 0.5;
          fill: var(--color--secondary);
        }

      `
          : ""
      }

      ${
        secondary
          ? `
        color: var(--color--secondary);
        opacity: 1;
      `
          : ""
      }
    }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    font-size: var(--font-size--large);
  }
`
);

export const PillButton = styled("button")(
  ({ theme, isActive, large }) => `
  /* Reset default button styling */
  appearance: none;
  background: transparent;
  border: 0;
  padding: 0;
  text-decoration: none;
  color: var(--color--secondary);
  user-select: none;

  /* padding: ${theme.spacing(1)} ${theme.spacing(2)}; */
  padding: 0.245em 0.6125em;
  border: 1px solid currentColor;
  border-radius: 40px;
  // cursor: pointer;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  &.is-active,
  &:hover:not(:disabled) {
    cursor: pointer;
    color: var(--color--tertiary);
    background-color: var(--color--secondary);
  }


  ${
    large
      ? `
    font-size: var(--font-size--large);
    `
      : ""
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    font-size: var(--font-size--large);

    ${
      large
        ? `
      font-size: var(--font-size--large);
      `
        : ""
    }

  }

`
);
