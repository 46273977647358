import * as React from 'react'
import { globalHistory } from '@reach/router'
import { read, save, getStateKey } from './session-storage' 

export const ScrollRestoration = ({ 
  children, 
  setHeaderFaded,
  headerHeight,
  ...rest 
}) => {
  const ref = React.useRef(null)
  const { location } = globalHistory

  React.useEffect(() => {
    if(!ref.current) return

    ref.current.addEventListener('scroll', handleScroll)
    return () => ref.current?.removeEventListener('scroll', handleScroll)
  }, [location])

  React.useEffect(() => {
    const { key } = location
    // console.log('fetching position from session storage')
    // console.log(`key: ${key}`)
    const scrollPosition = key ? read(location, key) : 0
    // console.log(scrollPosition)

    setTimeout(() => {
      ref?.current?.scroll(0, scrollPosition);
    }, 500)
  }, [location])

  let _isTicking = false
  let _latestKnownScrollY = 0
  const handleScroll = (event) => {
    _latestKnownScrollY = event.target.scrollTop

    if (!_isTicking) {
      _isTicking = true
      requestAnimationFrame(saveScroll)

      if (_latestKnownScrollY > headerHeight) {
        setHeaderFaded(true)
      } else {
        setHeaderFaded(false)
      }
    }
  }

  const saveScroll = () => {
    const key = location.key || null

    if(key) {
      save(
        location,
        location.key,
        _latestKnownScrollY
      )
    }

    _isTicking = false
  }

  return (
    <div ref={ ref } { ...rest }>
      { children }
    </div>
  )
}