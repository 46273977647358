import * as React from 'react'

export const RosterObservationContext = React.createContext(null)
export const RosterObservationDispatchContext = React.createContext(null)

export function RosterObservationProvider({ children }) {
  const [rosterInView, setRosterInView] = React.useState(null)

  return (
    <RosterObservationContext.Provider value={ rosterInView }>
      <RosterObservationDispatchContext.Provider value={ setRosterInView }>
        { children }
      </RosterObservationDispatchContext.Provider>
    </RosterObservationContext.Provider>
  )
}

export function useRosterObservation() {
  return React.useContext(RosterObservationContext);
}

export function useRosterObservationDispatch() {
  return React.useContext(RosterObservationDispatchContext);
}