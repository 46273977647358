import * as React from 'react'
import * as S from './styles'
import { useTheme } from '@mui/system'
import Box from '@mui/system/Box'
import { Stack } from '@mui/system'

// import { Box, Stack } from '../layout'
import { Button } from '../elements/button'

export const CopyFilter = () => {
  const theme = useTheme()
  const [copied, setCopied] = React.useState(false)

  return (
    <Stack direction='row' style={{ alignItems: 'center '}}>
      <Button 
        header
        noUnderline
        onClick={() => copy(setCopied)}
      >
        <Stack 
          columnGap={ 1.25 }
          direction='row' 
          sx={{ alignItems: 'center' }}
        >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              height: '2rem',
              [theme.breakpoints.up('md')]: {
                height: '1.15rem',
              }
            }}>
              <svg style={{ height: '100%' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.3 19.3"><path d="M5.8 14.3c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L13 5.2c.3-.3.8-.3 1.1 0s.3.8 0 1.1L6.3 14c-.1.2-.3.3-.5.3z"/><path d="M5.3 19.3c-1.4 0-2.7-.5-3.7-1.5S.1 15.5.1 14.1c0-1.4.5-2.7 1.5-3.7l2.7-2.7 1.1 1.1-2.7 2.7C1.9 12 1.5 13 1.5 14s.4 2 1.1 2.7c1.4 1.4 3.9 1.4 5.3 0l2.7-2.7 1.1 1.1L9 17.7c-1 1-2.3 1.6-3.7 1.6zM15 11.7l-1-1L16.7 8c1.5-1.5 1.5-3.9 0-5.3-1.4-1.4-3.9-1.4-5.3 0L8.6 5.3l-1-1 2.7-2.7c1-1 2.3-1.5 3.7-1.5 1.4 0 2.7.5 3.7 1.5 2.1 2.1 2.1 5.4 0 7.5L15 11.7z"/></svg>
            </Box>
          <Box 
            sx={{
              display: 'none',
              [theme.breakpoints.up('md')]: {
                display: 'block',
              }
            }}
          >
            Copy link
          </Box>
        </Stack>
      </Button>
      <Box style={{ marginLeft: '0.5rem', pointerEvents: 'none' }}>
        <S.Notification active={ copied }>Link copied</S.Notification>
      </Box>
    </Stack>
  )
}

const copy = (setCopied) => {
  const el = document.createElement('input');
  el.value = window.location.href;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  setCopied(true)

  setTimeout(() => {
    setCopied(false)
  }, 1000);
}