import { useStaticQuery, graphql } from 'gatsby'

export const useCollections = () => {
  const { collections } = useStaticQuery(
    graphql`
      query CollectionsMenu {
        collections: allSanityCollection {
          nodes {
            title
            slug {
              current
            }
          }
        }
      }
    `
  )

  return collections.nodes
}