import * as React from "react";
import { Box, Stack } from "@mui/system";
import { useTheme } from "@mui/system";
import { useCartCount, useToggleCart } from "../../../context/storeContext";

export const CartIcon = () => {
  const theme = useTheme();
  const toggleCart = useToggleCart();
  const cartCount = useCartCount();

  return (
    <Box
      sx={{
        position: "relative",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        justifyContent: "center",
        // mr: { md: "13px" },

        [theme.breakpoints.up("md")]: {
          mr: "13px",
        },
      }}
      onClick={() => toggleCart()}
    >
      {cartCount > 0 && (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            backgroundColor: "var(--color--primary)",
            padding: "5px 3px",
            width: { xs: "15px", md: "20px" },
            textAlign: "center",
            borderRadius: "50px",
            fontSize: { xs: "11px", md: "15px" },
            lineHeight: 1,
            transform: { xs: "translate(45%, 0)", md: "translate(45%, -25%)" },
          }}
        >
          {cartCount}
        </Box>
      )}
      <Box
        sx={{
          width: { md: "45px" },
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.7 35.2">
          <path d="M46 6.3c-.2-.3-.5-.4-.9-.4H29C29 2.8 26.5.3 23.4.3c-3.1 0-5.6 2.5-5.6 5.6H1.7c-.4 0-.7.2-.9.4-.2.3-.3.6-.3 1L4.9 34c.1.6.6 1 1.1 1h34.7c.6 0 1.1-.4 1.1-1l4.4-26.7c.1-.4 0-.7-.2-1zM23.4 2.6c1.8 0 3.3 1.5 3.3 3.3h-6.5c-.1-1.8 1.4-3.3 3.2-3.3zm16.3 30H7L3 8.2h40.7l-4 24.4z" />
        </svg>
      </Box>
    </Box>
  );
};
