import * as React from "react";
import { useTheme } from "@mui/system";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { StaticImage } from "gatsby-plugin-image";

import { Heading } from "../heading";
import { PillButton } from "../../components/elements/button";
import { LineItem } from "./lineItem";

import {
  useCartItems,
  useStore,
  useToggleCart,
  useCartTotals,
  useCheckout,
} from "../../context/storeContext";

// import { useCart, useCartDispatch } from "../../utils/CartContext";

export const Cart = () => {
  const theme = useTheme();
  // const cart = useCart();
  // const cartDispatch = useCartDispatch();
  const lineItems = useCartItems();
  const { cartIsOpen } = useStore();
  const toggleCart = useToggleCart();
  const { total } = useCartTotals();
  const openCheckout = useCheckout();

  // console.log(total);

  // console.log(lineItems);

  return (
    <>
      <Box
        onClick={() => toggleCart()}
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 2500,
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          transition: "opacity 0.25s ease-in-out, visibility 0.25s",
          visibility: cartIsOpen ? "visible" : "hidden",
          opacity: cartIsOpen ? 1 : 0,
        }}
      />
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 3000,
          backgroundColor: "#f5f5f5",
          width: { md: "576px" },
          transform: cartIsOpen ? "translateX(0)" : "translateX(100%)",
          transition: "transform 0.25s cubic-bezier(0.42, 0, 0, 1)",
        }}
      >
        <Stack
          sx={{
            py: 2.5,
            // px: 2,
            height: "fill-available",
            // height: "100vh",
            [theme.breakpoints.up("md")]: {
              py: 2,
              pb: 4,
            },
          }}
        >
          <Stack direction="row" sx={{ px: 2, pb: 1, alignItems: "center" }}>
            <Box>
              <Heading as="span">Your cart</Heading>
            </Box>

            <Box sx={{ ml: "auto" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  height: "2rem",
                  [theme.breakpoints.up("md")]: {
                    height: "1.15rem",
                  },
                }}
                onClick={() => toggleCart()}
              >
                <svg
                  style={{ height: "100%" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    style={
                      {
                        /* fill: '#ffffff' */
                      }
                    }
                    d="M19.55 1.61 18.33.4l-8.3 8.29L1.73.4.52 1.62l8.29 8.29-8.29 8.3 1.22 1.22 8.29-8.3 8.3 8.3 1.22-1.22-8.3-8.3z"
                  />
                </svg>
              </Box>
            </Box>
          </Stack>

          <Stack sx={{ mt: 1, pt: 2, flexShrink: 1, overflow: "scroll" }}>
            {lineItems.length > 0 ? (
              <Stack sx={{ gap: 4, px: 2, mb: 1 }}>
                {lineItems.map((item: { id: string; quantity: number }) => (
                  <React.Fragment key={item.id + item.quantity}>
                    <LineItem {...item} />
                  </React.Fragment>
                ))}
              </Stack>
            ) : (
              <Box sx={{ px: 2 }}>Cart is Empty</Box>
            )}
          </Stack>

          {lineItems.length > 0 && (
            <Stack sx={{ mt: "auto", px: 2, pt: 1, gap: 4 }}>
              <Stack direction="row">
                <Box>Subtotal</Box>
                <Box
                  sx={{
                    ml: "auto",
                    fontFamily: "var(--font--narrow)",
                  }}
                >
                  €{parseFloat(total)}
                </Box>
              </Stack>

              <Box>
                <PillButton
                  onClick={() => openCheckout()}
                  large
                  style={{ width: "100%" }}
                >
                  Continue to checkout
                </PillButton>

                {/* <button>Continue to checkout</button> */}
              </Box>
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  );
};
