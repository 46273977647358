import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion, AnimatePresence } from "framer-motion";

import { useLocation } from "@reach/router";
import { useTheme } from "@mui/system";
import Box from "@mui/system/Box";
import * as S from "./styles";

import { Preview } from "./preview";
import { Link } from "../elements/link";
import { Wordmark } from "../global/wordmark";

// import { useRoster } from '../../hooks/useRoster'
import { useElementSize } from "../../hooks/useElementSize";
import { useSiteSettings } from "../../hooks/useSiteSettings";
import { useMousePosition } from "../../hooks/useMousePosition";

import {
  useMenuDropdown,
  useMenuDropdownDispatch,
} from "../../utils/MenuDropdownContext";
import { Category } from "./category";

export const MenuDropdown = () => {
  const theme = useTheme();
  // const roster = useRoster()
  const siteSettings = useSiteSettings();
  const menuDropdown = useMenuDropdown();
  const setMenuDropdown = useMenuDropdownDispatch();
  const location = useLocation();

  const { menu } = siteSettings;

  const [isHovered, setIsHovered] = React.useState(false);
  const [preview, setPreview] = React.useState(null);
  const [rosterMinHeight, setRosterMinHeight] = React.useState(null);
  const [listItemRef, { height }] = useElementSize();

  // React.useEffect(() => {
  //   const activeMenuKey = menuDropdown.state.key;
  //   const activeMenu = menuDropdown.menus.filter(
  //     (el) => el._key === activeMenuKey
  //   );
  //   const entries = activeMenu[0]?.talentCategories.entries
  //     ? activeMenu[0].talentCategory.entries
  //     : null;

  //   const rosterCount = entries?.length;
  //   const namesPerColumn = rosterCount / 3;
  //   const roundedNamesPerColumn = Math.ceil(namesPerColumn);

  //   setRosterMinHeight(Math.ceil(roundedNamesPerColumn * height));
  // }, [height]);

  React.useEffect(() => {
    setIsHovered(false);
  }, [location]);

  // console.log(menuDropdown);

  const activeMenuKey = menuDropdown.state.key;
  const activeMenu = menuDropdown.menus.filter(
    (el) => el._key === activeMenuKey
  );
  const talentCategories = activeMenu[0]?.talentCategories
    ? activeMenu[0].talentCategories
    : null;

  // return null;

  // console.log(entries);

  return (
    <S.Menu isOpen={menuDropdown.state.isOpen}>
      <S.Container>
        {/* <Box
          component="ul"
          sx={{
            listStyleType: "none",
            margin: 0,
            padding: 0,
            zIndex: 10,
            [theme.breakpoints.up("md")]: {
              flex: 1,
              columnFill: "auto",
              columnCount: 3,
              height: "1px",
              minHeight: rosterMinHeight ? `${rosterMinHeight}px` : 0,
            },
          }}
        > */}
        {talentCategories && (
          <Box
            sx={{
              flex: 1,
              columnFill: "auto",
              columnCount: 3,
              height: "1px",
              zIndex: 1,
            }}
          >
            {talentCategories &&
              talentCategories.map((category, index) => (
                <Category
                  category={category}
                  isHovered={isHovered}
                  setPreview={setPreview}
                  setIsHovered={setIsHovered}
                  setMenuDropdown={setMenuDropdown}
                  key={index}
                />
              ))}
          </Box>
        )}
        {/* </Box> */}

        <Box>
          <Box
            sx={{
              display: "none",
              height: 45,
              [theme.breakpoints.up("md")]: {
                display: "block",
                mt: "auto",
                pt: 6,
              },
            }}
          >
            <Wordmark />
          </Box>
          <Box
            component="p"
            sx={{
              display: "none",

              fontSize: 13,
              letterSpacing: "-0.02em",

              [theme.breakpoints.up("md")]: {
                display: "block",
                // px: 2,
                mt: 0.5,
                mb: 1.5,
                textAlign: "center",
              },
            }}
          >
            {/* {siteSettings.summary} */}
            Bad Land is a creative management and production company for still
            <br />
            image and moving content based in Stockholm and Copenhagen.
          </Box>
        </Box>

        <Preview isHovered={isHovered} preview={preview} />
      </S.Container>
    </S.Menu>
  );
};
