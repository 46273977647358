import * as React from "react";
import { useLocation } from "@reach/router";
import Box from "@mui/system/Box";

import { Link } from "../../elements/link";
// import { Button } from '../../elements/button'
import { Accordion } from "../../accordion";

import {
  useMenuDropdown,
  useMenuDropdownDispatch,
} from "../../../utils/MenuDropdownContext";

const variations = {
  linkInternal: LinkInternal,
  linkDropdown: LinkDropdown,
};

export const MenuItem = ({
  data,
  toggleMobileMenu,
  accordionIsOpen,
  setAccordionIsOpen,
}) => {
  const { _type, key } = data;
  const VariationType = variations[_type];

  return (
    VariationType && (
      <li key={key}>
        <VariationType
          data={data}
          toggleMobileMenu={toggleMobileMenu}
          accordionIsOpen={accordionIsOpen}
          setAccordionIsOpen={setAccordionIsOpen}
        />
      </li>
    )
  );
};

function LinkInternal({ data, toggleMobileMenu, accordionIsOpen }) {
  const location = useLocation();
  const setMenuDropdown = useMenuDropdownDispatch();
  const menuDropdown = useMenuDropdown();

  const { label, reference } = data;

  let slug;
  if (reference._type === "contactSingleton") {
    slug = "/contact";
  } else if (reference._type === "productionSingleton") {
    slug = "/production";
  } else if (reference.slug.current) {
    slug = `/collection/${reference.slug.current}`;
  }

  return (
    <Link
      noUnderline
      location={location}
      // header={ accordionIsOpen ? true : false }
      skew={accordionIsOpen ? false : true}
      to={slug}
      onClick={(event) => {
        toggleMobileMenu(false);
      }}
    >
      {label}
    </Link>
  );
}

function LinkDropdown({
  data,
  toggleMobileMenu,
  accordionIsOpen,
  setAccordionIsOpen,
}) {
  const { label, talentCategories } = data;

  return (
    <Accordion
      heading={label}
      setAccordionIsOpen={setAccordionIsOpen}
      accordionIsOpen={accordionIsOpen}
    >
      {talentCategories.map((category, index) => (
        <Box sx={{ textAlign: "center", pt: 4 }}>
          <Box
            sx={{
              textTransform: "uppercase",
              fontSize: 12,
              letterSpacing: "-0.02em",
            }}
          >
            {category.title}
          </Box>

          <Box
            component="ul"
            sx={{
              listStyleType: "none",
              p: 0,
              m: 0,
              // mb: 2,
              fontSize: "var(--font-size--mobileMenu)",
            }}
          >
            {category.entries.map((entry, index) => (
              <Box key={index} component="li" sx={{ textAlign: "center" }}>
                <Link
                  noUnderline
                  to={`/${entry.talent?.slug?.current}`}
                  // style={{ width: "fit-content" }}
                  onClick={(event) => {
                    toggleMobileMenu(false);
                    setAccordionIsOpen(false);
                  }}
                >
                  {entry.talent?.title}
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Accordion>
  );
}
