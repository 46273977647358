import * as React from "react";
import Box from "@mui/system/Box";
import { Stack } from "@mui/system";

import { ExternalLink, Link } from "../../elements/link";
import { Wordmark } from "../wordmark";

import { useSiteSettings } from "../../../hooks/useSiteSettings";

export const Footer = () => {
  const siteSettings = useSiteSettings();
  const { offices, links } = siteSettings;

  console.log(links);

  return (
    <Stack
      sx={{
        mt: "auto",
        pt: 4,
        px: 2,
        pb: 1.5,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Link to="/" style={{ display: "block", width: "100%" }}>
        <Wordmark />
      </Link>
      <Stack sx={{ mt: 1, py: 0, px: 2 }}>
        <Stack
          direction="row"
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            columnGap: 2,
            rowGap: { xs: 2, md: 0 },
          }}
        >
          {offices.map((office) => (
            <Stack
              key={office._key}
              direction={{ md: "row" }}
              sx={{ textAlign: { xs: "center" }, columnGap: 2 }}
            >
              <Box sx={{ display: "inline" }}>
                <Box
                  component="span"
                  sx={{
                    display: { xs: "block", md: "inline" },
                    textTransform: "uppercase",
                  }}
                >
                  {office.title}
                </Box>{" "}
                {office.address}
              </Box>
              <Box sx={{ display: "inline" }}>{office.vat}</Box>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ mt: 1, columnGap: 2 }}>
        {links.map((link) => {
          if (link._type === "emailField") {
            return (
              <ExternalLink key={link._key} small href={`mailto:${link.value}`}>
                {link.label}
              </ExternalLink>
            );
          } else if (link._type === "link") {
            return (
              <ExternalLink
                key={link._key}
                small
                target="_blank"
                href={link.value}
              >
                {link.label}
              </ExternalLink>
            );
          } else if (link._type === "linkInternal") {
            return (
              <Link key={link._key} small to="/terms-and-conditions">
                {link.label}
              </Link>
            );
          } else {
            return null;
          }
        })}
      </Stack>
    </Stack>
  );
};
