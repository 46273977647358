import * as React from 'react'
import { globalHistory } from '@reach/router'

export const LoaderContext = React.createContext(null)
export const LoaderDispatchContext = React.createContext(null)

export function LoaderProvider({ children }) {
  const [loader, setLoader] = React.useState(true)
  const { location } = globalHistory

  React.useEffect(() => {
    if(location?.pathname === '/') {
      setTimeout(() => {
        setLoader(false)
      }, 250)
    } else {
      setLoader(false)
    }



  }, [])

  return (
    <LoaderContext.Provider value={ loader }>
      <LoaderDispatchContext.Provider value={ setLoader }>
        { children }
      </LoaderDispatchContext.Provider>
    </LoaderContext.Provider>
  )
}

export function useLoader() {
  return React.useContext(LoaderContext);
}

export function useLoaderDispatch() {
  return React.useContext(LoaderDispatchContext);
}