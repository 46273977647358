import * as React from "react";
import styled from "@mui/system/styled";

const StyledFigure = styled("figure")`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

export const Wordmark = () => (
  <StyledFigure>
    <svg
      style={{ width: "100%", height: "100%" }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 337.92"
    >
      <path d="M757.62 47.73q-17.89-22.44-46.77-35.08T640.45 0H531.63v337.92h108.82q41.52 0 70.4-12.65t46.77-34.84q17.91-22.2 26-53t8.11-67.54q0-37.23-8.11-68.49t-26-53.67Zm-82.33 178.74q-11.94 20.76-32.93 20.76h-6.21V90.68h6.21q21 0 32.93 20.76t11.93 58.47q0 35.8-11.93 56.56Z" />
      <path d="M987.25 0H882.72v337.92h191.4v-95.46h-86.87V0z" />
      <path d="M1550.25 164.66h-1.91L1495.36 0h-110.25v337.92h92.12l-3.82-159.41h1.43l53.93 159.41h107.39V0h-88.77l2.86 164.66z" />
      <path d="M1911.89 101.42q-8.13-31.25-26-53.69t-46.79-35.08Q1810.21.01 1768.7 0h-108.82v337.92h108.82q41.52 0 70.4-12.65t46.77-34.84q17.91-22.2 26-53t8.11-67.54q.02-37.21-8.09-68.47Zm-108.35 125q-11.94 20.76-32.93 20.76h-6.21V90.68h6.21q21 0 32.93 20.76t11.93 58.47q0 35.8-11.93 56.56Z" />
      <path d="m1164.41 0-79.71 337.92h103.57l9.07-46.78h58.23l9.07 46.78H1373L1292.8 0Zm44.39 211 16.7-109.78h1.91L1243.16 211Z" />
      <path d="m311.23 0-79.71 337.92h103.57l9.07-46.78h58.23l9.07 46.78H519.8L439.62 0Zm44.39 211 16.7-109.78h1.91L390 210.96Z" />
      <path d="M221.7 188.53q-15-19.09-35.08-25.3l-.48-1.43a73.92 73.92 0 0 0 28.4-27.21q10.74-17.65 10.74-44.86 0-29.12-10.5-47t-26.49-27.22A101.68 101.68 0 0 0 153.21 3.1a224.8 224.8 0 0 0-35.8-3.1H0v337.92h129.35a158.26 158.26 0 0 0 39.85-5 93.54 93.54 0 0 0 34.36-16.71q15-11.68 24.11-30.55t9.06-46.53q0-31.51-15.03-50.6ZM95.93 85.43h17.19q9.54 0 15.75 5.25t6.2 15.75q0 12.42-6 17.9t-16 5.49H95.93Zm36 159.72q-6.93 6.38-17.9 6.38h-18.1v-48.21h18.14a27.36 27.36 0 0 1 17.18 5.91q7.63 5.91 7.64 18.66 0 10.91-6.89 17.26Z" />
    </svg>
  </StyledFigure>
);
