import { useStaticQuery, graphql } from "gatsby";

export const useSiteSettings = () => {
  const { siteSettings } = useStaticQuery(
    graphql`
      query SiteSettings {
        siteSettings: allSanitySiteSettings {
          nodes {
            offices {
              address
              title
              vat
              _key
              _type
            }
            links {
              ... on SanityEmailField {
                _key
                _type
                label
                value
              }
              ... on SanityLink {
                _key
                _type
                label
                value
              }

              ... on SanityLinkInternal {
                _key
                _type
                label
                increasedDistance
                reference {
                  ... on SanityCollection {
                    _type
                    slug {
                      current
                    }
                  }
                  ... on SanityTermsAndConditionsSingleton {
                    _type
                  }
                  ... on SanityContactSingleton {
                    _type
                  }
                  ... on SanityProductionSingleton {
                    _type
                  }
                }
              }
            }
            summary
            # roster {
            #   preview {
            #     asset {
            #       gatsbyImageData
            #     }
            #   }
            #   talent {
            #     slug {
            #       current
            #     }
            #     title
            #   }
            # }

            menu {
              ... on SanityLinkDropdown {
                _key
                _type
                label
                increasedDistance
                talentCategories {
                  entries {
                    preview {
                      asset {
                        gatsbyImageData(width: 500)
                      }
                    }
                    talent {
                      slug {
                        current
                      }
                      title
                    }
                  }
                  title
                }
              }

              ... on SanityLinkInternal {
                _key
                _type
                label
                increasedDistance
                reference {
                  ... on SanityCollection {
                    _type
                    slug {
                      current
                    }
                  }
                  ... on SanityTermsAndConditionsSingleton {
                    _type
                  }
                  ... on SanityContactSingleton {
                    _type
                  }
                  ... on SanityProductionSingleton {
                    _type
                  }
                }
              }
            }

            shopMenu {
              ... on SanityLinkShop {
                _key
                _type
                label
                reference {
                  ... on SanityCollection {
                    slug {
                      current
                    }
                  }
                }
              }
            }

            filter {
              title
              entries {
                talent {
                  slug {
                    current
                  }
                  title
                }
              }
            }
          }
        }
      }
    `
  );

  return siteSettings.nodes[0];
};
