import * as React from 'react'

export const GridObservationContext = React.createContext(null)
export const GridObservationDispatchContext = React.createContext(null)

export function GridObservationProvider({ children }) {
  const [observed, setObserved] = React.useState(false)

  return (
    <GridObservationContext.Provider value={ observed }>
      <GridObservationDispatchContext.Provider value={ setObserved }>
        { children }
      </GridObservationDispatchContext.Provider>
    </GridObservationContext.Provider>
  )
}

export function useGridObservation() {
  return React.useContext(GridObservationContext);
}

export function useGridObservationDispatch() {
  return React.useContext(GridObservationDispatchContext);
}