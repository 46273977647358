import * as React from 'react'
import { useSiteSettings } from '../hooks/useSiteSettings'

export const MenuDropdownContext = React.createContext(null)
export const MenuDropdownDispatchContext = React.createContext(null)

export function MenuDropdownProvider({ children }) {
  const siteSettings = useSiteSettings()
  const { menu } = siteSettings
  const menus = menu.filter(el => el._type === 'linkDropdown')


  const [menuDropdown, setMenuDropdown] = React.useState({
    state: {
      isOpen: false,
      key: null,
    },
    menus: []
  })

  React.useEffect(() => {
    setMenuDropdown((prevState) => {
      return { ...prevState, menus: [...menus] }
    })
  },[])

  // console.log(menuDropdown)

  return (
    <MenuDropdownContext.Provider value={menuDropdown}>
      <MenuDropdownDispatchContext.Provider value={setMenuDropdown}>
        { children }
      </MenuDropdownDispatchContext.Provider>
    </MenuDropdownContext.Provider>
  )
}

export function useMenuDropdown() {
  return React.useContext(MenuDropdownContext)
}

export function useMenuDropdownDispatch() {
  return React.useContext(MenuDropdownDispatchContext)
}