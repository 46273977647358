exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contactPage.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-production-page-tsx": () => import("./../../../src/templates/productionPage.tsx" /* webpackChunkName: "component---src-templates-production-page-tsx" */),
  "component---src-templates-production-tsx": () => import("./../../../src/templates/production.tsx" /* webpackChunkName: "component---src-templates-production-tsx" */),
  "component---src-templates-talent-tsx": () => import("./../../../src/templates/talent.tsx" /* webpackChunkName: "component---src-templates-talent-tsx" */),
  "component---src-templates-terms-and-conditions-page-tsx": () => import("./../../../src/templates/termsAndConditionsPage.tsx" /* webpackChunkName: "component---src-templates-terms-and-conditions-page-tsx" */),
  "component---src-templates-work-tsx": () => import("./../../../src/templates/work.tsx" /* webpackChunkName: "component---src-templates-work-tsx" */)
}

